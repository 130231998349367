export default {
  h1: {
    fontWeight: 500,
    fontSize: 45,
    lineHeight: "52px",
    fontFamily: "Outfit",
    "@media (max-width:767px)": {
      fontSize: "30px !important",
      lineHeight: "40px",
    },
  },
  h2: {
    fontWeight: 400,
    fontSize: 40,
    lineHeight: "45px",
    fontFamily: "Outfit",
    wordBreak: "break-word",
    "@media (max-width:767px)": {
      fontSize: "25px !important",
      lineHeight: "30px !important",
    },
  },
  h3: {
    fontWeight: 600,
    fontSize: 28,
    lineHeight: "40px",
    color: "#0C111D",
    fontFamily: "Outfit",
    "@media (max-width:767px)": {
      fontSize: "22px !important",
    },
  },
  h4: {
    fontWeight: 600,
    fontSize: 25,
    lineHeight: "30px",
    fontFamily: "Outfit",
    "@media (max-width:767px)": {
      fontSize: "18px !important",
    },
  },
  h5: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "30px",
    fontFamily: "Outfit",
    "@media (max-width:767px)": {
      fontSize: "16px !important",
    },
  },
  h6: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "24px",
    color: "#262626",
    fontFamily: "Outfit",
    "@media (max-width:767px)": {
      lineHeight: "22px",
      fontSize: "14px !important",
    },
  },
  overline: {
    fontWeight: 500,
    fontFamily: "Outfit",
  },

  body2: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "20px",
    fontFamily: "Outfit",
    color: "rgba(52, 64, 84, 1)",
    "@media (max-width:767px)": {
      fontSize: "15px",
      lineHeight: "18px",
    },
  },
  body1: {
    fontWeight: 400,
    lineHeight: "20px",
    fontSize: 12,
    fontFamily: "Outfit",
  },

  // ...... Project Custom Typography ....... //

  mainHeading: {
    fontWeight: 600,
    fontSize: 24,
    color: "#080515",
    fontFamily: "Outfit",
    "@media(maxWidth:767px)": {
      fontSize: "20px !important",
    },
  },
  button: {
    fontWeight: "700",
    fontSize: "15px",
    fontFamily: "Outfit",
  },
  label: {
    fontWeight: 500,
    fontSize: 16,
    color: "black",
    fontFamily: "Outfit",
    "@media(maxWidth:767px)": {
      fontSize: "14px !important",
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 22,
    color: "#080515",
    fontFamily: "Outfit",
    "@media(maxWidth:767px)": {
      fontSize: "12px !important",
    },
  },
  detail: {
    fontWeight: 400,
    fontSize: 14,
    color: "#080515",
    fontFamily: "Outfit",
    "@media(maxWidth:767px)": {
      fontSize: "12px !important",
    },
  },

  tableCell: {
    fontWeight: 500,
    fontSize: 14,
    color: "#080515",
    fontFamily: "Outfit",
    "@media(maxWidth:767px)": {
      fontSize: "8px !important",
    },
    "&:hover": {
      background: "linear-gradient(135deg, #5E58E4, #ADABF4) !important", // Change this to your desired hover background color
      color: "white", // Change this to your desired hover font color
    },
    "&:focus": {
      background: "linear-gradient(135deg, #5E58E4, #ADABF4) !important", // Change this to your desired focus background color
      color: "white", // Change this to your desired focus font color
    },
  },
};
