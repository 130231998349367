import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  AppBar,
  Toolbar,
  Button,
  CssBaseline,
  useMediaQuery,
  Select,
  MenuItem,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import logo from "../assets/Logo.svg";
import i18n from "../config/i18n";
import { useTranslation } from "react-i18next";

const Header = styled(AppBar)(({ theme }) => ({
  background: theme.palette.primary.main,
  position: "sticky",
}));

const Footer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  padding: theme.spacing(2),
  textAlign: "center",
}));

const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const AuthLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(
    sessionStorage.getItem("language") || "en"
  );

  useEffect(() => {
    const dir = language === "ar" ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", dir);
    document.body.style.direction = dir;
    document.body.style.textAlign = language === "ar" ? "right" : "left";
  }, [language]);

  const handleLanguageChange = async (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    await i18n.changeLanguage(selectedLanguage);
    sessionStorage.setItem("language", selectedLanguage);
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <CssBaseline />
      <Header>
        <Toolbar>
          <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
            <img
              src={logo}
              alt="healthcare_logo"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          </Typography>
          <Select
            value={language}
            onChange={handleLanguageChange}
            sx={{
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "5px 15px",
              minWidth: "120px",
              "& .MuiSelect-icon": {
                color: "white !important",
              },
              "& .MuiSelect-select": {
                color: "white",
                fontWeight: 600,
              },
            }}
          >
            <MenuItem
              value="en"
              sx={{ fontFamily: "Outfit", color: "#3f51b5" }}
            >
              English
            </MenuItem>
            <MenuItem
              value="ar"
              sx={{
                fontFamily: "Tajawal, Arial",
                color: "#3f51b5",
                textAlign: "right",
              }}
            >
              العربية
            </MenuItem>
          </Select>
        </Toolbar>
      </Header>
      <Content>
        <Container maxWidth="sm">{children}</Container>
      </Content>
      <Footer>
        <Typography variant="body2" color="textPrimary">
          {t("copyrightMessage", { year: new Date().getFullYear() })}
        </Typography>
      </Footer>
    </Box>
  );
};

export default AuthLayout;
