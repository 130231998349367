import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";


export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

const login = (userData) => {
  // Parse the permissions cookie
  const getPermissionsFromCookie = () => {
    const permissionsCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("permissions="));

    if (!permissionsCookie) {
      console.log("Permissions cookie not found");
      return null;
    }

    const permissionsString = permissionsCookie.split("=")[1];
    console.log("Raw permissions string:", permissionsString); // Log the raw string

    try {
      return permissionsString
        ? JSON.parse(decodeURIComponent(permissionsString))
        : [];
    } catch (error) {
      console.error("Error parsing permissions cookie:", error);
      return [];
    }
  };

  const permissions = getPermissionsFromCookie();
  console.log("Permissions from cookie:", permissions);

  console.log("Logging in with userData:", userData); // Debugging
  sessionStorage.setItem("user", JSON.stringify(userData));
  sessionStorage.setItem("adminToken", userData.token);
};

const handleLogOut = (t) => {
  // Function to remove cookies
  const removeCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
  };

  // Remove specific cookies
  removeCookie("permissions");
  removeCookie("loggedin");
  // Remove items from sessionStorage
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("adminToken");

  // Debugging: Check if items are removed
  console.log("user after removal:", sessionStorage.getItem("user"));
  console.log(
    "adminToken after removal:",
    sessionStorage.getItem("adminToken")
  );

  // Redirect to home page
  window.location.href = "/";
  toast.success(t("logoutSuccess"));
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});
  const [notificationCount, setNotificationCount] = useState(0);
  const { t} = useTranslation();
 

  let data = {
    login,
    userLoggedIn: isLogin,
    setIsLogin,
    userData,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    handleLogOut: () => handleLogOut(t),
    notificationCount,
    setNotificationCount,
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
