// src/components/Unauthorized.jsx
import React from "react";
import { Button, Container, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        textAlign: "center",
        marginTop: "10vh",
        padding: "2rem",
        backgroundColor: "#f0f4f8",
        borderRadius: "8px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box mb={3}>
        <Typography
          variant="h4"
          style={{ fontWeight: "bold", color: "#E15499" }}
        >
          Access Denied
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="body1" color="textSecondary">
          Sorry, you don't have permission to view this page.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography
          variant="h4"
          style={{ fontWeight: "bold", color: "#5E58E4" }}
        >
          Navigating you to previous page.
        </Typography>
      </Box>
    </Container>
  );
};

export default Unauthorized;
