import axios from "axios";
import ApiConfig from "./apiConfig";
import toast from "react-hot-toast";

export const postDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const getDataHandlerWithToken = async (endPoint, query, data) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      params: query,
      data: data,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const postDataHandlerWithToken = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      data: data,
    });
    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const putDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};

export const putDataHandlerWithToken = async (endPoint, data, params) => {
  console.log(data);
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      data: data,
      params: params,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};

export const deleteDataHandler = async (endPoint, query) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      params: query,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const patchDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: ApiConfig[endPoint],
      data: data,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      toast.error(res.data.responseMessage);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    console.log(error);
  }
};

export const patchTokenDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.responseMessage);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    console.log(error);
  }
};

export const listCategory = async (params) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig.listCategory,
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      params: params,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getCategoryWithToken = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "GET", // Adjusted to GET method
      url: `${ApiConfig[endPoint]}?categoryId=${data.categoryId}`,
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
    });
    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getDataHandler = async (endPoint, query) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      params: query,
    });
    console.log("URL:", ApiConfig[endPoint]);

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const listBanner = async (endPoint, query) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      params: query,

      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getData = async (endPoint) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const translateToArabic = async (text) => {
  const API_KEY = process.env.REACT_APP_OPEN_AI_TRANSLATER_API_KEY;

  console.log(API_KEY, "OpenAI API Key");

  try {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "You are a helpful assistant that translates text to Arabic.",
          },
          {
            role: "user",
            content: `Translate the following text to Arabic:\n\n${text}`,
          },
        ],
        max_tokens: 100,
        temperature: 0.3,
      }),
    });

    const data = await response.json();

    
    if (data.choices && data.choices[0] && data.choices[0].message) {
      const translation = data.choices[0].message.content.trim();

   
      if (translation.includes("I’m sorry") || translation.length === 0) {
        return "Translation not available, please provide a complete sentence."; 
      }

      return translation; 
    } else {
      throw new Error("Unexpected API response format");
    }
  } catch (error) {
    console.error("Translation Error:", error);
    return "Translation not available, please try again.";
  }
};

export const transliterateToLanguage = async (text) => {
  const API_KEY = process.env.REACT_APP_OPEN_AI_TRANSLATER_API_KEY;

  console.log(API_KEY, "OpenAI API Key");

  // Function to detect language
  const detectLanguage = (text) => {
    const arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(text) ? "arabic" : "english";
  };

  try {
    const language = detectLanguage(text);
    const targetLanguage = language === "english" ? "Arabic" : "English";

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: `You are a helpful assistant that translates text from ${language === "english" ? "English" : "Arabic"} to ${targetLanguage}.`,
          },
          {
            role: "user",
            content: `Translate the following text to ${targetLanguage}:\n\n${text}`,
          },
        ],
        max_tokens: 100,
        temperature: 0.3,
      }),
    });

    const data = await response.json();

    if (data.choices && data.choices[0] && data.choices[0].message) {
      const translation = data.choices[0].message.content.trim();

      if (translation.includes("I’m sorry") || translation.length === 0) {
        return "Translation not available, please provide a complete sentence."; 
      }

      return translation; 
    } else {
      throw new Error("Unexpected API response format");
    }
  } catch (error) {
    console.error("Translation Error:", error);
    return "Translation not available, please try again.";
  }
};




