import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locales/en.json';
import ar from '../locales/ar.json';

const defaultLanguage = 'en';
const storedLanguage = sessionStorage.getItem('language') || defaultLanguage;


// const handleLanguageChange = (lang) => {
//   i18n.changeLanguage(lang); // This will update the language
//   // setLanguage(lang);
//   sessionStorage.setItem("language", lang);
// };


i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    ar: { translation: ar },
  },
  lng: storedLanguage, 
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
});

i18n.on('languageChanged', (lang) => {
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  document.body.dir = direction;
  document.documentElement.lang = lang; 
});

export default i18n;

