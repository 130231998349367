import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/Auth";

export default function AuthGuard({ children }) {
  const location = useLocation();
  const auth = useContext(AuthContext);

  const nonGuardedPaths = ["/login", "/"];
  const guardedPaths = [
    // Add your guarded paths here
  ];

  // If not authenticated and trying to access a guarded path
  if (!auth.userLoggedIn && guardedPaths.includes(location.pathname)) {
    return <Navigate to="/" replace />;
  }

  // If authenticated and accessing a non-guarded path
  if (nonGuardedPaths.includes(location.pathname)) {
    if (localStorage.getItem("userRole") === "PAT") {
      return <Navigate to="/patient-dashboard" replace />;
    }
    if (localStorage.getItem("userRole") === "DOC") {
      return <Navigate to="/doctor-appointment" replace />;
    }
  }

  return <>{children}</>;
}
